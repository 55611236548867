.conf-root {
  background-color: white;

  .device {
    padding: 1rem;

    .label {
      display: flex;
      align-items: center;
    }

    div.grid-switch {
      padding-left: 0;
    }
    div.grid-slider {
      padding-left: 24px;
    }
  }
}

.device-info-container {
  margin-top: 1em !important;
}

.name-input {
  display: flex !important;

  input {
    padding: 5px 10px;
    padding-left: 0;
    outline: none;
    border: none;

    &:focus {
      outline: 1px dotted #212121;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }
}

.flex {
  display: flex;
}

.edit-name-input {
  cursor: pointer;
}

.time {
  font-weight: 500;
}

.back-button {
  margin-top: 50px !important;
  margin-left: 20px;
}

.MuiButtonBase-root.MuiListItem-root.inactive.MuiListItem-gutters.MuiListItem-button {
  background-color: #eee;
  color: #888;
  border: 1px solid #ffff;
  padding-top: 7px;
  padding-bottom: 7px;
}