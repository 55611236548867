.legend {
  display: flex;
  height: calc(var(--vh, 1vh) * 5);
  align-items: flex-start;
  justify-content: center;
}

.sensors {
  width: 100%;
  height: calc(calc(var(--vh, 1vh) * 90));

  display:grid;
  grid-template-columns: 50% 50%;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: flex-end;
  align-content: center;
  user-select: none;
}

.sensors > * {
  &:nth-child(2n + 2) {
    justify-self: flex-start;
  }
}

.temp-legend {

  line {
    display: none;
  }

  path{
    display: none;
  }

  text{
    fill: #ccc;
    font-size: 1em;
  }
}

@media only screen and (max-width: 600px) {
  .flex-row div.tabs {
    display: none;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;

  &.full-height > * {
    height: 100%;
  }

  .react-swipeable-view-container {
    height: 100%;
  }

  .MuiSvgIcon-root {
    color: white;
    width: 2em;
    height: 2em;
    margin-left: 1em;
  }

  .tabs {
    height: calc(var(--vh) * 30);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .slide-container {
    display: flex;
    max-height: calc(var(--vh) * 95);
  }
}

.full-width {
  width: 100%;
}

div.sauna-model {
  font-family: 'Roboto', sans-serif;
  min-height: 400px;
  min-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  user-select: none;

  .left-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
    align-items: center;
  }

  .sauna-slider {
    min-height: 300px !important;
    width: 50px;
    display: block;
  }

  .big-number {
    margin-left: 10px;
    font-size: 50px;
  }
}