body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.auto-hide {
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 1s !important;
}

.hide-button {
  opacity: 0;
}

.be-good {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  font-size: calc(min(10vw, 50px));
}