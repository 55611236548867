@media only screen and (max-width: 500px) {
  .sky {
    div.moon-phase {
      font-size: 13px;

      &.active {
        .name {
          font-size: 18px;
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .sky {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.sky-container {
  display: flex;
  width: 100vw;
  height: 100%;
  align-items: center;
}

.sky {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100vw;
  height: 500px;

  .sun {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;

    img {
      height: calc(100vh / 10);
      margin: 1em 0;
    }

    .center {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .moon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    height: 100%;
    position: relative;

    .moon-phases {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #777;
      gap: 10px;

      .moon-phase {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        &.active {
          color: #ccc;
          .name {
            font-size: 25px;
          }
        }


      }
    }
  }
}

.moon-wrap {
  position: absolute;
  transform: scale(.5);
  clip-path: circle(45%);
}