@media only screen and (max-width: 1400px) {
  div.prediction {
    .days {
      .day {
        text-align: center;

        .header-day {
          display: none;
        }
        .header-day-fl {
          display: inline;
          font-weight: bold;
          font-size: 2em;
        }

        svg {
          width: 100%;
        }

        .wind {
          font-size: 17px;

          .w-min, .w-sep {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  div.prediction {
    .chart {
      flex: 0 0 69%;
    }
    .symbol-img {
      display: none;
    }
    .days {
      .day {
        padding: 0.5em;
        margin: .2em;

        .header-date {
          .date-part {
            display: none;
          }
        }

        .wind {
          font-size: 14px;
          word-break: break-all;
        }
      }
    }
  }
}


.prediction {
  display: flex;
  color: white;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .chart {
    height: calc(100% * 0.6);
    flex: 0 0 60%;
  }

  svg {
    line {
      stroke-width: 0.6px;
      color: rgba(255, 255, 255, .33)
    }
    .domain {
      stroke-width: 0.1px;
      color: rgba(255, 255, 255, .33)
    }

    .x-axis text {
      transform: translateY(5px);
      font-size: 15px;
    }

    .y-axis text {
      transform: translateX(-5px);
      font-size: 15px;
    }

    .y-axis-right {
      font-size: 15px;
      .domain, line {
        display: none;
      }
    }

    path.temp {
      fill: none;
      stroke: url(#line-gradient);
      stroke-width: 4px;
    }
  }

  .days {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-left: 30px;
    width: calc(100vw - 60px);
    justify-content: space-between;

    .day {
      height: auto;
      margin: 1em .75em;
      padding: 1em;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 1 0px;
      width: 0;

      &:first-child {
        margin-left: 0;
      }

      > * {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .header-day {
        font-size: 2em;
        margin: 0;
      }

      .header-day-fl {
        display: none;
      }

      .header-date {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .day-part {
          font-weight: bold;
        }
      }

      .header-temp {
        display: none;
        font-size: 1.3em;
        flex-grow: 2;
        width: 100%;
        justify-content: space-around;
        font-weight: bold;
      }
    }
  }

  .day-1 {
    background-color: rgb(63, 108, 166);
  }
  .day-2 {
    background-color: rgb(112, 78, 105);
  }
  .day-3 {
    background-color: rgb(47, 115, 52);;
  }
  .day-4 {
    background-color: rgb(166, 117, 56);;
  }
  .day-5 {
    background-color: rgb(166, 98, 83);;
  }
  .day-6 {
    background-color: #663E18;
  }
  .day-7 {
    background-color: #348899;
  }
}