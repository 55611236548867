.history-root {
    background-color: white;

    .grid line {
      stroke: lightgrey;
      stroke-opacity: 0.7;
      shape-rendering: crispEdges;
    }

    .grid path {
      stroke-width: 0;
    }

    .overlay {
      fill: none;
      pointer-events: all;
    }

    .focus text {
      font-size: 14px;
    }

    .tooltip {
      fill: white;
      stroke: #000;
    }

    .tooltip-row-1, .tooltip-row-2 {
      font-weight: bold;
    }
}

.history-devices {
  justify-content: center;

  .device {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    height: 3em;
    box-shadow: 0 1px 3px rgba(0,0,0,0.09), 0 1px 2px rgba(0,0,0,0.09);
    color: rgba(0,0,0,0.70);

    cursor: pointer;
    margin: 0 .3em;

    &:hover {
      background-color: #eee;
    }

    &.selected {
      background-color: #ccc;
    }
  }
}

.device-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

div.chart-control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div:first-child {
    border-right: 1px solid #ccc;
  }
}

.MuiFormControlLabel-labelPlacementTop {
  margin-left: 16px;
  flex-direction: column-reverse;
  width: 65px;
}