@import url('https://fonts.googleapis.com/css?family=Stardos+Stencil&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  background-color: #282c34;
  // background-color: #000;
  height: 100%;
  //font-family: 'Stardos Stencil', cursive;
  font-family: 'Roboto', sans-serif;
}

body.black {
  background-color: #000;
}

.content {
  height: calc(var(--vh, 1vh) * 100);
}

.nav {
  display: flex;
  justify-content: space-between;
  height: calc(var(--vh, 1vh) * 5);

  button {
    color: white;
  }
}

.routes {
  height: calc(var(--vh, 1vh) * 95);
  > * {
    height: 100%;
  }
}

.MuiListItem-button {
  a {
    color: inherit;
    text-decoration: none;
  }
}

.white {
  color: white !important;
}
