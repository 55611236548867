.electricity {
    color: #fff;

    &.missing {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .chart {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .stat {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 17px;

      .current {
        font-size: 30px;

        &.max {
          color: #f55;
        }

        &.not-max {
          color: #0F0;
        }
      }
    }

    svg {
        line {
          stroke-width: 0.6px;
          color: rgba(255, 255, 255, .33)
        }
        .domain {
          stroke-width: 0.1px;
          color: rgba(255, 255, 255, .33)
        }

        .x-axis text {
          transform: translateY(5px);
          font-size: 15px;
        }

        .y-axis text {
          transform: translateX(-5px);
          font-size: 15px;
        }

        .area {
          fill: url(#area-gradient);
          stroke-width: 0px;
        }
    }
}
